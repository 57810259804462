import Aos from "aos";
(function($) {

	Aos.init();
	//console.log(init)
	//$('.main-menu').waypoint('sticky');
	
	$('.main-menu-toggle').click(function(){
		

		if($('html').hasClass('main-menu-open'))
		{
			
			$('html').removeClass('main-menu-open');
			$('.menu-toggle .label').text('Menu');
			

		} else {
			
			$('html').addClass('main-menu-open');
			$('.menu-toggle .label').text('Close');
			
		}
		
		
		return false;
		
	}); 

	$('.timeline-toggle').on('click', function(e) {

		e.preventDefault();

		var timeline = $('#timeline');
		timeline.toggleClass('collapsed');
		timeline.toggleClass('open');
		$(this).html('Hide Timeline');

		if(timeline.hasClass('open')) {
			$(this).html('Hide Timeline');
		} else {
			$(this).html('View Full Timeline');
		}

		$("#timeline").get(0).scrollIntoView({behavior: 'smooth'});

	})
	
	
	$('.blocker').click(function(){
		

		if($('html').hasClass('main-menu-open'))
		{
			
			$('html').removeClass('main-menu-open');
			$('.menu-toggle .label').text('Menu');
		
		}
		
		
		return false;
		
	}); 
	
	
	/*	---------------------------------------------------------------------------------------
		scrolling nav
		---------------------------------------------------------------------------------------
	*/
	var u = scrollY,
		 i = 0,
		 a = 0;

	var trigger = $('.masthead').outerHeight();

	$(window).scroll(function(e){
		u = i, i = scrollY, a = i - u, requestAnimationFrame(function(e) {
			i > 0 ? document.documentElement.classList.add("scrolled") : document.documentElement.classList.remove("scrolled"), a < 0 ? (document.documentElement.classList.add("header-sticky"), document.documentElement.classList.add("scroll-up"),  document.documentElement.classList.remove("scroll-down")) : (document.documentElement.classList.remove("header-sticky"), document.documentElement.classList.add("scroll-down"), document.documentElement.classList.remove("scroll-up")),  scrollY > trigger ? document.documentElement.classList.add("masthead-slideup") : document.documentElement.classList.remove("masthead-slideup");
		})
	});
	
	$(window).trigger('scroll');
	
	/*	---------------------------------------------------------------------------------------
		Sub Menu
		---------------------------------------------------------------------------------------
	*/


	$('ul.sub-menu').wrap('<div class="sub-menu-wrap"></div>');
	$('li.has-sub').append('<span><i class="far fa-chevron-down"></i></span>');
	
	$('.top-level > .has-sub > a, .top-level > .has-sub > span').on("click", function(){
	
		if($('html').hasClass('main-menu-open'))
		{
			$('.top-level > .has-sub.m-open').not($(this).parent()).removeClass('m-open');
			$($(this).parent()).toggleClass('m-open');

			return false;

		}

	}); 
	
	
	$('.has-sub .has-sub > a, .has-sub .has-sub > span').on("click", function(){
		
		if($('html').hasClass('main-menu-open'))
		{
		
			$($(this).parent()).toggleClass('m-open');

			return false;
		
		}

	}); 
	
	
	
	/*
	$('.top-level .has-sub > a').click(function(){
		
		if($(window).outerWidth() > 1050)
		{
			$(this).parent().toggleClass('open');
			
			return false;
			
		} 
		
	}); 

	
	$('.top-level .has-sub > a, .top-level .has-sub > span').click(function(){
		
		if($(window).outerWidth() <= 1020)
		{
			$(this).parent().toggleClass('open');
			
			return false;
			
		} 
		
	}); 
	*.

	
	$('.button').append('<span class="arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.297 20.672"><g id="Group_3" data-name="Group 3" transform="translate(1 1.414)"><path id="Path_2" data-name="Path 2" d="M1639.918,2545.2h25.433" transform="translate(-1639.918 -2536.485)" fill="none"  stroke-linecap="round" stroke-width="2"/><path id="Path_3" data-name="Path 3" d="M1657.254,2536.485l8.388,8.426-8.388,9.42" transform="translate(-1639.345 -2536.485)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg></span>')
	
	/*	---------------------------------------------------------------------------------------
		Toggle Link
		---------------------------------------------------------------------------------------
	*/

	$('.toggle-link').on("click", function(){
		
		var t = $(this).data('toggle-target');
		var c = $(this).data('toggle-class');
		
		
		//check for a parent remove
		if($(this).attr('data-group-remove-class')) {
			var grc = $(this).data('group-remove-class');
			$(grc).find('.' + c).not(t).removeClass(c);
		}
		
		

		
		
		//toggle the class
		if(t==='parent') {
			
			$(this).parent().toggleClass(c);

		} else if( $(this).hasClass('inactive-not-allowed') && $(this).hasClass(c)) {
		
			
		} else {
			
			$(t).toggleClass(c);
			
			if($(this).hasClass('toggle-text')){
				
				if($(t).hasClass(c)){
					$(this).text($(this).data('active-text'));
				} else {
					$(this).text($(this).data('initial-text'));		 
				}
				
			}
		}
		
		

		//
		if($(this).hasClass('toggle-self')){
			$(this).toggleClass(c);
		}
		

		//check for a scroll
		if ($(this).attr('data-scrolltarget') && $(t).hasClass(c)) {
			var s = $(this).attr('data-scrolltarget');
			windowAnimateTo($,s,-20);
		}
		
		
		if($(this).hasClass('searchformtoggle')){
			
			setTimeout(function(){
				
				$('.search-form-block input').focus();
			}, 300);
			
		}
		
		if($(this).attr('href')){
			return false;
		}
		
		
		
	}); 
	


	/*	---------------------------------------------------------------------------------------
		Jump Link
		---------------------------------------------------------------------------------------
	*/
	
	$('.jump-link').on("click", function(){
		
		var t = $(this).data('jump-target');
		windowAnimateTo($,t,0);
		
		if($(this).attr('href')){
			return false;
		}
		
	}); 

	
	/*	---------------------------------------------------------------------------------------
		Fitvids plugin / used on iframes
		---------------------------------------------------------------------------------------
	*/
	$('.videowrap').fitVids();
	if($('.text-content iframe').length){
		$('.text-content iframe').each(function(){
			$(this).parent().addClass('fitvids');
			$('.fitvids').fitVids();
		});
	}

	$('.fitvids').fitVids();

	/*	---------------------------------------------------------------------------------------
		Inview
		---------------------------------------------------------------------------------------
	*/
	$('.add-inview').on('inview', function() {
		$(this).addClass('is-inview').removeClass('add-inview');
	});
	
	$('.add-inview-status').on('inview', function(event, isInView) {
	  if (isInView) {
		$(this).addClass('is-inview');
	  } else {
		$(this).removeClass('is-inview');
	  }
	});

	
	
	/*	---------------------------------------------------------------------------------------
		Owl
		---------------------------------------------------------------------------------------
	*/
	
	$('.logo-grid-carousel').each(function(){
		
		var dotsContainer = $(this).parent().find('.dots-container');
		
		$(this).owlCarousel({
		
			dotsSpeed: 300,
			items:4,
			dots: true,
			nav: false,
			margin:30,
			dotsContainer: dotsContainer,
			
			responsive:{
				0:{
					items:1
				},
				600:{
					items:2
				},
				1200:{
					items:3
				},
				1201:{
					items:4
				}
				
			}
			
		});
		
	});
	
	$('.gallery-carousel').each(function(){
		
		var arrowsContainer = $(this).parent().find('.arrows-container');

		$(this).owlCarousel({
			
			items:1,
			loop: true,
			nav: true,
			dots: false,
			navSpeed: 400,
			navContainer: arrowsContainer,
			navText: ['<button class="slick-prev"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve"><rect  width="35" height="35" class="rect"/><g><path class="arrow" d="M22.4,9.4l-7.7,7.7l7.8,8.7c0.4,0.4,0.3,1-0.1,1.4s-1,0.3-1.4-0.1l-8.4-9.4c-0.4-0.4-0.3-1,0-1.4L21,7.9c0.4-0.4,1-0.4,1.4,0C22.8,8.3,22.8,9,22.4,9.4L22.4,9.4z"/></g></svg></button>','<button class="slick-next"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 35 35" style="enable-background:new 0 0 35 35;" xml:space="preserve"><rect class="rect" width="35" height="35"/><g><path class="arrow" d="M12.6,9.3l7.7,7.8l-7.8,8.7c-0.4,0.4-0.3,1,0.1,1.4c0.4,0.4,1,0.3,1.4-0.1l8.4-9.4c0.4-0.4,0.3-1,0-1.4L14,7.9c-0.4-0.4-1-0.4-1.4,0C12.2,8.3,12.2,8.9,12.6,9.3L12.6,9.3z"/></g></svg></button>'],
		});

	
	});
	
	
	/*	---------------------------------------------------------------------------------------
		Magnific
		---------------------------------------------------------------------------------------
	*/
	
	// $('.popup-image').magnificPopup({
	// 	type: 'image',
	// });
	
	
	/*	---------------------------------------------------------------------------------------
		Events tickts plus
		---------------------------------------------------------------------------------------
	*/
	
	if($('.tribe-tickets__rsvp-actions-button-going').length > 0)
	{
			
		setTimeout(function(){
			
			$('.tribe-tickets__rsvp-actions-button-going').trigger('click');
			
		},400);
	}
	
	/*	---------------------------------------------------------------------------------------
		Responsive image map
		---------------------------------------------------------------------------------------
	*/
	
	$('img[usemap]').rwdImageMaps(); 

	$('.pob-sites-map area').hover(
		
	  function() {
		  
		  var mpid = $(this).attr('id');
		  $('.map-overlays .'+mpid).addClass('active');

	  }, function() {

		  var mpid = $(this).attr('id');
		  $('.map-overlays .'+mpid).removeClass('active');

	  }
	);
	
	
	/*	---------------------------------------------------------------------------------------
		Vimeo
		---------------------------------------------------------------------------------------
	*/
	
	/* Grabbing objects to play with */
	
	 $('.video-play').on('click',function(){
		 
		 var iframe = $(this).parent().find('iframe');
		 $(this).parent().addClass('playing');
		 var player = new Vimeo.Player(iframe);
		 player.play();
	 });
	
	/*
	var playButtons = $('.video-play');


	playButtons.click(function() {
	  var iframe = document.getElementById(this.dataset.id);
	  var player = new Vimeo.Player(iframe);
      player.play();
	  iframe.parentElement.classList.add('playing');
	});
	*/


})( jQuery );



/*	---------------------------------------------------------------------------------------
	Animate the window to a point
	---------------------------------------------------------------------------------------
*/
function windowAnimateTo($,point,offset)
{
	
	disable_scroll();
	{
		$('html, body').stop().animate({
			scrollTop: $(point).offset().top + offset
			}, 
			800, 
			function() { 
			enable_scroll();
			}
		);
	}
}

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = [37, 38, 39, 40];

function keydown(e) {
    for (var i = keys.length; i--;) {
        if (e.keyCode === keys[i]) {
            preventDefault(e);
            return;
        }
    }
}

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
      e.preventDefault();
  e.returnValue = false;  
}

function wheel(e) {
  preventDefault(e);
}

function disable_scroll() {
  if (window.addEventListener) {
      window.addEventListener('DOMMouseScroll', wheel, false);
  }
  window.onmousewheel = document.onmousewheel = wheel;
  document.onkeydown = keydown;
}

function enable_scroll() {
    if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', wheel, false);
    }
    window.onmousewheel = document.onmousewheel = document.onkeydown = null;  
}
